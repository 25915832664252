import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { CenteredContainer2 } from "../base_components/layout";
import { darkBlue, white } from "../base_components/colors";
import { sizes } from "../base_components/screenSizes";
import { Col, Row } from "../base_components/layout";

import { SPh2, SPbody1 } from "../base_components/typography";

const smallMarginSize = 50;
const largeMarginSize = 100;
const xlMarginSize = 200;

export const MediaLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
`;

export const MediaLinkWrapper = styled.a`
  img {
    width: 40px;
    padding: 10px 22px 8px 0px;

    @media (max-width: ${sizes.medium}px) {
        width: 30px;
    }

    @media (max-width: ${sizes.small}px) {
        width: 25px;
    }

    }
  }
`;

export const Link = styled(AnchorLink)`
  color: ${white};
  text-decoration: none;
`;

export const FooterRow = styled(Row)`
  width: 100%;
  flex-wrap: initial;

  @media (max-width: ${sizes.medium}px) {
         flex-wrap: wrap;
  }

`;

export const FooterBrowseRow = styled(Row)`
  width: 100%;
  flex-wrap: initial;

`;

export const FooterHeaderStyle = styled(SPh2)``;

export const FooterBodyStyle = styled(SPbody1)`
`;

export const LogoWrapper = styled.div`
  svg {
    fill: ${white};
    width: 185px;
    @media (max-width: ${sizes.medium}px) {
        width: 150px;
    }

  }

  padding: 24px 24px 0px 24px;


`;

export const StayConnectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: ${darkBlue};
  color: ${white};
`;

export const StayConnectedCol = styled(Col)`
  width: 100%;
  padding: 24px;
  ustify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  display: flex;
  flex-direction: column;
`;

export const FooterLinkCol = styled(StayConnectedCol)`
  padding: 20px;
  justify-content: flex-start;
  :first-child,
  :last-child {
    padding-top: 0px;
  }
`;

export const StepText = styled(CenteredContainer2)`
  height: 400px;
  background: lightgrey;
  flex-direction: column;

  @media (min-width: ${sizes.xl}px) {
    width: calc(100% - ${xlMarginSize}px);
    margin-left: ${props => (props.isRightPhoto ? `${xlMarginSize}px` : 0)};
  }

  @media (min-width: ${sizes.large}px) {
    width: calc(100% - ${largeMarginSize}px);
    margin-left: ${props => (props.isRightPhoto ? `${largeMarginSize}px` : 0)};
  }

  @media (min-width: ${sizes.small}px) {
    width: calc(100% - ${smallMarginSize}px);
    margin-left: ${props => (props.isRightPhoto ? `${smallMarginSize}px` : 0)};
  }

  @media (max-width: ${sizes.xs}px) {
    width: 100%;
  }
`;
