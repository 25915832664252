import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  HeaderWrapper,
  HeaderLinkGroup,
  HeaderLink,
  HeaderButton,
  HeaderContainer,
  HeaderPage,
  LogoWrapper,
  HamburgerMenuContainer,
} from "./header_styles";
import { Link } from "gatsby";

import Hamburger from "./hamburger";

import Logo from "../../images/logo.svg";
import { white } from "../base_components/colors";

import Modal from "../base_components/modal/modal";
import EmailPopUp from "../base_components/email_pop_up";

const Header = ({ siteTitle }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isThankYou, setIsThankYou] = useState(false);
  const content = (
    <EmailPopUp onClick={setIsThankYou} isThankYou={isThankYou} />
  );

  return (
    <HeaderContainer>
      <Modal
        isModalVisible={isModalVisible}
        closeModal={setModalVisible}
        content={content}
        isNotification={true}
      />
      <HeaderWrapper>
        <Link
          to="/"
          style={{ lineHeight: 0, position: "absolute", top: "10px", left: 0 }}
        >
          <LogoWrapper>
            <Logo style={{ fill: "white" }} />
          </LogoWrapper>
        </Link>
        <HamburgerMenuContainer>
          {<Hamburger openJoinNow={setModalVisible} />}
        </HamburgerMenuContainer>
        <HeaderLinkGroup>
          <HeaderLink
            to="/"
            activeStyle={{ borderBottom: `4px solid ${white}` }}
          >
            <HeaderPage>Home</HeaderPage>
          </HeaderLink>
          <HeaderLink
            to="/about/"
            activeStyle={{ borderBottom: `4px solid ${white}` }}
          >
            <HeaderPage>About</HeaderPage>
          </HeaderLink>
          <HeaderButton onClick={() => setModalVisible(true)}>
            <HeaderPage>Join Now</HeaderPage>
          </HeaderButton>
        </HeaderLinkGroup>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `TestTitle`,
};

export default Header;
