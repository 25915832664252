import styled from 'styled-components';
import { white, orange } from "./colors";

import { SPbody1 } from "./typography";

export const ErrorMsgStyle = styled(SPbody1)`
    margin: 0;
    position: absolute;
    bottom: -25px;
    left: 0;
    color: ${white};
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  padding: 14px;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.error ? `${orange}` : 'white'};
  margin: 0;

  border-radius: 10px;
  font-family: "Karla";
`
