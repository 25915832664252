import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledInput, ErrorMsgStyle, InputContainer } from './textinput_styles';


const TextField = styled(({
  id,
  name,
  value,
  placeholder,
  hasError,
  errorMsg,
  isTouched,
  hintText,
  type,
  onChange,
  disabled,
  className,
  errorName
}) => {
  const error = hasError;
  return (
    <InputContainer>
      {error && <ErrorMsgStyle>{errorMsg}</ErrorMsgStyle>}
      <StyledInput
      autocomplete="off"
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
      />
    </InputContainer>

  )
})``
TextField.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
};
export {
  TextField
};