export const white = "#ffffff";
export const darkBlue = "#014A55";
export const mediumBlue = "#22B0A5";
export const lightBlue = "#E9F7F4";
export const orange = "#F68B3B";
export const yellow = "#FED684";
export const pillBackgroundBlue = "#014A55";
export const pillBackgroundGreen = "#1FA69D";
export const pillBackgroundGreenDark = "#075A62";
export const grey = "#444444";

export const colorsList = ["white", "darkBlue", "mediumBlue", "orange", "yellow", "grey"];
export const colorMap = {"white": white, "darkBlue": darkBlue, "mediumBlue": mediumBlue, 
"lightBlue": lightBlue,
"pillBackgroundBlue": pillBackgroundBlue,
"pillBackgroundGreen": pillBackgroundGreen,
"pillBackgroundGreenDark": pillBackgroundGreenDark,
"orange": orange, "yellow": yellow, "grey": grey}
