import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import {
  StayConnectedContainer,
  StayConnectedCol,
  FooterLinkCol,
  FooterBodyStyle,
  FooterHeaderStyle,
  Link,
  LogoWrapper,
  FooterRow,
  FooterBrowseRow,
  MediaLinkContainer,
  MediaLinkWrapper,
} from "./stayconnected_styles";
import StayConnectedForm from "../base_components/stay_connected_form";
import EmailPopUp from "../base_components/email_pop_up";
import Modal from "../base_components/modal/modal";
import Logo from "../../images/logo.svg";

const StayConnected = props => {
  const { data } = props;

  const [isModalVisible, setModalVisible] = useState(false);

  const content = <EmailPopUp isThankYou={true} />;

  const emailAddress =
    data.prismic.allFooters.edges[0].node.email_address[0].text;
  const signupTitle =
    data.prismic.allFooters.edges[0].node.signup_title[0].text;

  const signupMessage =
    data.prismic.allFooters.edges[0].node.signup_message[0].text;

  const socialMediaLinks =
    data.prismic.allFooters.edges[0].node.social_media_links;

  return (
    <StayConnectedContainer>
      <Modal
        isModalVisible={isModalVisible}
        closeModal={setModalVisible}
        content={content}
        isNotification={true}
        onJoin={setModalVisible}
      />
      <LogoWrapper>
        <Logo/>
      </LogoWrapper>
      <FooterRow>
        <StayConnectedCol size={1} breakSize={"medium"}>
          <FooterHeaderStyle>{signupTitle}</FooterHeaderStyle>
          <FooterBodyStyle>{signupMessage}</FooterBodyStyle>
          <StayConnectedForm onJoin={setModalVisible} isLeft={true}/>
        </StayConnectedCol>

        <StayConnectedCol size={1} breakSize={"medium"}>
          <FooterBrowseRow>
            <FooterLinkCol size={1}>
              <FooterHeaderStyle>Browse</FooterHeaderStyle>
              <Link to="/#how_to_use">
                <FooterBodyStyle>How To Use</FooterBodyStyle>
              </Link>
              <Link to="/#benefits">
                <FooterBodyStyle>Benefits</FooterBodyStyle>
              </Link>
              <Link to="/about#why_we_started">
                <FooterBodyStyle>Why We Started</FooterBodyStyle>
              </Link>
              <Link to="/about#timeline">
                <FooterBodyStyle>Timeline</FooterBodyStyle>
              </Link>
              <Link to="/about#our_team">
                <FooterBodyStyle>Our Team</FooterBodyStyle>
              </Link>
            </FooterLinkCol>

            <FooterLinkCol size={1}>
              <FooterHeaderStyle>Contact</FooterHeaderStyle>
              <FooterBodyStyle>{emailAddress}</FooterBodyStyle>
              <MediaLinkContainer>
                {socialMediaLinks.map((item, index) => {
                  const linkImg = item.social_media_icon.url;
                  const linkUrl = item.social_media_link.url;
                  return (
                    <MediaLinkWrapper href={linkUrl} target="_blank">
                      <img src={linkImg} alt={"social media link"} />
                    </MediaLinkWrapper>
                  );
                })}
              </MediaLinkContainer>
            </FooterLinkCol>
          </FooterBrowseRow>
        </StayConnectedCol>
      </FooterRow>
    </StayConnectedContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allFooters {
            edges {
              node {
                email_address
                signup_title
                signup_message
                social_media_links {
                  social_media_icon
                  social_media_link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <StayConnected data={data} {...props} />}
  />
);
