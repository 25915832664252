import React from "react";
import Fade from "react-reveal/Fade";

import {
  HamburgerContainer,
  Bar,
  HamburgerMenu,
  Bar1,
  Bar2,
  MenuContainer,
  MenuContentContainer,
  HambHeaderLink,
  HambHeaderPage,
  HambLinkContainer,
  LinkContainer,
  HambButton,
} from "./hamburger_styles";

import { LogoWrapper } from "./header_styles";
import { Link } from "gatsby";

import Logo from "../../images/logo.svg";

const Hamburger = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClick = props.openJoinNow;

  const toggle = () => setIsOpen(!isOpen);
  return (
    <HamburgerContainer onClick={toggle}>
      {renderStep(isOpen, onClick)}
    </HamburgerContainer>
  );
};

function renderStep(isOpen, onClick) {
  if (!isOpen) {
    return (
      <HamburgerMenu>
        <Bar></Bar>
        <Bar></Bar>
        <Bar></Bar>
      </HamburgerMenu>
    );
  } else {
    return (
      <MenuContainer>
        <LinkContainer>
          <Link to="/" style={{ lineHeight: 0 }}>
            <LogoWrapper>
              <Logo style={{ fill: "grey" }} />
            </LogoWrapper>
          </Link>
        </LinkContainer>
        <HamburgerMenu isOpen>
          <Bar1></Bar1>
          <Bar2></Bar2>
        </HamburgerMenu>

        <MenuContentContainer>
          <HambLinkContainer>
            <HambHeaderLink to="/" activeStyle={{ color: "red" }}>
              <Fade top>
                <HambHeaderPage>Home</HambHeaderPage>
              </Fade>
            </HambHeaderLink>
            <HambHeaderLink to="/about/" activeStyle={{ color: "red" }}>
              <Fade top>
                <HambHeaderPage>About</HambHeaderPage>
              </Fade>
            </HambHeaderLink>

            <Fade top>
              <HambButton onClick={() => onClick(true)}>
                <HambHeaderPage>Join Now</HambHeaderPage>
              </HambButton>
            </Fade>
          </HambLinkContainer>
        </MenuContentContainer>
      </MenuContainer>
    );
  }
}

export default Hamburger;
