import styled from "styled-components";

import { orange, white } from "../base_components/colors";

export const PrimaryButtonStyle = styled.button`
    padding: 8px;
    border-radius: 10px;
    background-color: ${orange};
    border-color: ${orange};
    color: ${white};
    width: auto;
    height: 100%;
    cursor: pointer;
    font-family: "Crete Round";
    font-size: 14px;
`;



