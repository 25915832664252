import styled from "styled-components";

import { sizes } from "../../base_components/screenSizes";
import { darkBlue, grey, yellow } from "../../base_components/colors";
import { Col, Row } from "../../base_components/layout";

import { SPsh1, SPh2, SPbody1} from "../../base_components/typography";

export const DateStyle = styled(SPsh1)`
    color: ${darkBlue};
`;

export const TitleStyle = styled(SPh2)`
    color: ${darkBlue};
`;

export const DetailStyle = styled(SPbody1)`
    color: ${grey};
`;

export const EventImg = styled.img`
  width: 100%;
  border-radius: 10px;
  box-shadow: -20px -20px ${yellow};
`;


export const EventCol = styled(Col)`
`;

export const EventRow = styled(Row)`
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: center;
    flex-direction: ${props => (props.isRightPhoto ? "row-reverse" : "initial")};


  @media (max-width: ${sizes.large}px) {

  }
`;

export const EventImgContainer = styled.div`

  padding: 40px;
  margin-left: 20px;

  @media (max-width: ${sizes.large}px) {
        padding: 0px;
  }


  @media (max-width: ${sizes.medium}px) {
        padding: 10px 40px;
  }
`;


export const EventDetailContainer = styled.div`

  padding: 40px;


  @media (max-width: ${sizes.medium}px) {
    padding: 10px 40px;
  }
`;
