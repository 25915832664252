import styled from "styled-components";
import React from "react";

import { Row } from "../base_components/layout";
import { colorMap } from "../base_components/colors";

import Circle from "../../images/circle.svg";
import MediumPill from "../../images/pill-medium.svg";
import LongPill from "../../images/pill-long.svg";

import { Parallax } from "react-scroll-parallax";

const parallaxByTen = 10;

const ParallaxWrapper = styled(Parallax)`
  width: auto;
`;

const Wrapper = styled.div`
  width: auto;
  stroke: ${props =>
    props.outlineColor ? `${colorMap[props.outlineColor]}` : `none`};
  fill: ${props => (props.fillColor ? `${colorMap[props.fillColor]}` : `none`)};
`;

const PillRow = styled(Row)`
  flex-direction: row;
  padding: 8px;
  stroke-opacity: 0.4;
  fill-opacity: 0.4;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  transition: all 1s ease;
  right: ${props => (props.right ? `${props.right}px` : "inherit")};
  top: ${props => (props.top ? `${props.top}px` : `inherit`)};
  bottom: ${props => (props.bottom ? `${props.bottom}px` : `inherit`)};
  left: ${props => (props.left ? `${props.left}px` : `inherit`)};

  z-index: ${props => (props.zindex ? `${props.zindex}` : `1`)};
  ${"" /* 

    @media (max-width: ${sizes.medium}px) {
        width: 368px;
    }
    
    @media (max-width: ${sizes.small}px) {
        width: 230px;
    } */}
`;

const BackgroundPattern = props => {
  const { strokeColor, fillColor, left, right, top, bottom, zindex } = props;

  return (
    <BackgroundWrapper
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      zindex={zindex}
    >
      <ParallaxWrapper x={[-parallaxByTen * 3, parallaxByTen * 3]}>
        <PillRow>
          <Wrapper outlineColor={strokeColor}>
            <Circle />
          </Wrapper>
          <Wrapper fillColor={fillColor}>
            <LongPill />
          </Wrapper>
        </PillRow>
      </ParallaxWrapper>

      <ParallaxWrapper x={[-parallaxByTen, parallaxByTen]}>
        <PillRow>
          <Wrapper outlineColor={strokeColor}>
            <LongPill />
          </Wrapper>
          <Wrapper outlineColor={strokeColor}>
            <MediumPill />
          </Wrapper>
        </PillRow>
      </ParallaxWrapper>

      <ParallaxWrapper x={[-parallaxByTen * 2, parallaxByTen * 2]}>
        <PillRow x={[-parallaxByTen, parallaxByTen]}>
          <Wrapper outlineColor={strokeColor}>
            <Circle />
          </Wrapper>
          <Wrapper fillColor={fillColor}>
            <MediumPill />
          </Wrapper>
          <Wrapper outlineColor={strokeColor}>
            <LongPill />
          </Wrapper>
        </PillRow>
      </ParallaxWrapper>

      <ParallaxWrapper x={[-parallaxByTen, parallaxByTen]}>
        <PillRow>
          <Wrapper outlineColor={strokeColor}>
            <LongPill />
          </Wrapper>
          <Wrapper fillColor={fillColor}>
            <Circle />
          </Wrapper>
        </PillRow>
      </ParallaxWrapper>
    </BackgroundWrapper>
  );
};

export default BackgroundPattern;
