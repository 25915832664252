import React, { useState, createRef } from "react";
import styled from "styled-components";

import { TextField } from "../base_components/textinput";
import { sizes } from "../base_components/screenSizes";
import { PrimaryButtonStyle } from "../base_components/buttons";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items:  ${props => (props.isLeft ? "flex-start" : "center")};
  margin-top: 20px;
  width: 100%;
`;

const Container = styled.form`
  width: 100%;
`;

const FormStyle = styled.form`
  display: flex;
  flex-flow: row wrap;
  max-width: 800px;
  width: 100%;
`;

const EmailFieldWrapper = styled.div`
  flex-basis: 65%;
  @media (max-width: ${sizes.medium}px) {
    flex-basis: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 35%;
  justify-content: center;
  flex: 1;
  height: 47px;
  button {
    width: 100%;
    margin-left: 40px;
    @media (max-width: ${sizes.medium}px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  @media (max-width: ${sizes.medium}px) {
    flex-basis: 100%;
    margin-top: 20px;
  }
`;

const StayConnectedForm = props => {
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [isFirstTry, setFirstTry] = useState(true);

  const formRef = createRef(null);

  const isInputEmail = str => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isMatch = str.match(regex);
    return isMatch !== null;
  };

  const onChange = e => {
    let email = e.target.value;
    setEmail(email);

    if (!isFirstTry) {
      const isEmail = isInputEmail(email);
      setIsError(!isEmail);
    }
  };


  const onSubmit = (event, props) => {
    // this prevents the auto redirect to google forms thank you submission
    event.preventDefault();

    const data = new FormData(event.target);
    const isEmail = isInputEmail(email);

    if (props.onClick && isEmail && email !== "") {
      props.onClick(true);
    }

    setIsError(!isEmail);
    setFirstTry(false);

    if (isEmail && email !== "") {
      formRef.current.reset();
      setEmail("");


      if (props.onJoin) {
        props.onJoin(true);
      }

      // TODO: this is where you can change what the form submits to!
      fetch('https://docs.google.com/forms/u/1/d/e/1FAIpQLSe-uDWF_eSfvjmu2amoAPLT4xq30zHMq-KoDLrz1yvSq6FKOw/formResponse', {
        method: 'POST',
        body: data,
      });

    }
  };

  return (
    <Container>
      <FormContainer isLeft={props.isLeft}>
        <FormStyle
          class="form"
          onSubmit={(e) => onSubmit(e, props)}
          ref={el => (formRef.current = el)}
          autocomplete="off"
        >
          <EmailFieldWrapper>
            <TextField
              autocomplete="off"
              hasError={isError}
              placeholder={"Enter Email"}
              type={"text"}
              name={"entry.2028109507"}
              onChange={e => onChange(e)}
              errorMsg={"Please input a valid email address"}
            />
          </EmailFieldWrapper>
          <ButtonWrapper>
            <PrimaryButtonStyle type="submit">Join Now</PrimaryButtonStyle>
          </ButtonWrapper>
        </FormStyle>
      </FormContainer>
    </Container>
  );
};

export default StayConnectedForm;
