import styled from "styled-components";
import { SPh1 } from "../base_components/typography";
import { Link } from "gatsby";

import { white, darkBlue, orange } from "../base_components/colors";

export const HamburgerContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  z-index: 10000;
`;

export const LinkContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  z-index: 10000;
  position: fixed;
  left: 0;
  top: 10px;
`;

export const Bar = styled.div`
  width: 50px;
  height: 3px;
  background-color: ${white};
  margin: 8px 0;
  border-radius: 50px;
`;
export const Bar1 = styled.div`
  width: 50px;
  height: 3px;
  background-color: ${darkBlue};
  margin: 8px 0;
  border-radius: 50px;
  -webkit-transform: rotate(-45deg) translate(-6px, 8.5px);
  transform: rotate(-45deg) translate(-6px, 8.5px);
`;
export const Bar2 = styled.div`
  width: 50px;
  height: 3px;
  background-color: ${darkBlue};
  margin: 8px 0;
  border-radius: 50px;
  -webkit-transform: rotate(45deg) translate(1px, -2px);
  transform: rotate(45deg) translate(1px, -2px);
`;

export const HamburgerMenu = styled.div`
  display: inline-block;
  cursor: pointer;
  z-index: 10000;
  padding-top: 0;
  padding-bottom: 0;

  position: ${props => (props.isOpen ? `fixed` : "absolute")};
  top: 0;
  right: 0;
  padding: 25px;
`;

export const MenuContainer = styled.div`
  display: none;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  right: 0;

  @media (max-width: 975px) {
    display: flex;
  }

  @media (min-width: 0px) {
    padding-left: 0;
  }
  @media (min-width: 768px) {
    padding-left: 0;
  }
  @media (min-width: 992px) {
    padding-left: 0;
  }
`;

export const MenuContentContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  background-color: ${white};
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;
export const HambLinkContainer = styled.div`
  text-align: center;
`;

export const HambHeaderLink = styled(Link)`
  text-decoration: none;
  color: ${darkBlue};

  :hover {
    color: white;
  }
`;

export const HambHeaderPage = styled(SPh1)`
  color: ${darkBlue};
  padding: 20px;

  :hover {
    border-bottom: 4px solid ${darkBlue};
  }
`;

export const HambButton = styled.div`
  background-color: ${orange};
  height: 70px;
  border-radius: 40px;
  display: flex;
  align-items: center;

  p {
    color: ${white};
    margin-bottom: 0;
  }
`;
