import React from "react";

import {
  ModalMain,
  ModalBody,
  ModalContent,
  CloseContainer,
  Bar1,
  Bar2,
} from "./modal_styles";

const Modal = props => {
  const { isModalVisible, closeModal, isNotification } = props;

  return (
    <ModalMain isModalVisible={isModalVisible}>
      <ModalBody isNotification={isNotification}>
        <CloseContainer onClick={() => closeModal(false)}>
          <Bar1 isNotification={isNotification} />
          <Bar2 isNotification={isNotification} />
        </CloseContainer>
        <ModalContent isNotification={isNotification}>
          {props.content}
        </ModalContent>
      </ModalBody>
    </ModalMain>
  );
};

export default Modal;
