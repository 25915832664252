import styled from "styled-components";
import React from "react";
import { sizes } from "./screenSizes";

// Font Styles

const Base = styled.p`
  margin: 0;
`;

export const SPh1 = styled(Base)`
  font-family: "Crete Round";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 66px;
  margin-bottom: 20px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

export const SPh2 = styled(Base)`
  font-family: "Crete Round";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const SPsh1 = styled(Base)`
  font-family: "Karla";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const SPsh2 = styled(Base)`
  font-family: "Karla";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 18px;
    line-height: 40px;
  }
`;

export const SPbody1 = styled(Base)`
  font-family: "Karla";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SPbody2 = styled(Base)`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

// export const Link = styled(Link)`
//   color: black;
//   &:focus {
//     outline-color: golde;
//   }
// `;

export const Typography = props => {
  return <SPh1 />;
};
