import styled from 'styled-components';
import { sizes } from "./screenSizes";

// media sizes for different devices

const media = {
    xs:  `
    @media(max-width: ${sizes.xs}px) {
    flex-basis: 100%;
    }
    `,
    small:  `
    @media(max-width: ${sizes.small}px) {
    flex-basis: 100%;
    }
    `,
    medium:  `
    @media(max-width: ${sizes.medium}px) {
    flex-basis: 100%;
    }
    `,
    large:  `
    @media(max-width: ${sizes.large}px) {
    flex-basis: 100%;
    }
    `,
}

export const Grid = styled.div`
    overflow: ${props => (props.isNotHidden ? `initial` : `hidden`)};;   
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Col = styled.div`
    flex: ${(props) => props.size};
    ${(props) => props.breakSize && media[props.breakSize]
    }
`;


// Body and Container styles
export const SectionContainer = styled.div`
    padding: 80px;
    position: relative;
    z-index: 1;

@media(max-width: ${sizes.large}px) {
      padding: 40px 20px;
}

@media(max-width: ${sizes.medium}px) {
      padding: 60px 20px;
  }
`;

export const PageContainer = styled.div`
  
`;

export const SplashContainer = styled.div`
    height: 90vh;
    min-height: 600px;
    background: grey;
    position: relative;
    flex-direction: column;
`;

export const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const CenteredContainer2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
`;

export const BottomContainer = styled.div`
    position: absolute;
    bottom: 0;
`;