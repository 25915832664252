import React from "react";
import { StaticQuery, graphql } from "gatsby";

import styled from "styled-components";
import StayConnectedForm from "./stay_connected_form";

import { SPh2, SPsh1 } from "./typography";

import BackgroundPattern from "./background";

const Container = styled.div`
  text-align: center;
  padding: 40px;
  z-index: 1;
`;

const TitleStyle = styled(SPh2)``;

const BodyStyle = styled(SPsh1)``;

const EmailPopUp = props => {
  const { isThankYou, onClick, data } = props;

  const thankyouTitle =
    data.prismic.allEmail_popups.edges[0].node.thankyou_popup_title[0].text;

  const thankyouMessage =
    data.prismic.allEmail_popups.edges[0].node.thankyou_popup_message[0].text;

  const signupTitle =
    data.prismic.allEmail_popups.edges[0].node.signup_popup_title[0].text;

  const signupMessage =
    data.prismic.allEmail_popups.edges[0].node.signup_popup_message[0].text;

  if (isThankYou) {
    return (
      <Container>
        <BackgroundPattern
          strokeColor={"pillBackgroundGreen"}
          fillColor={"pillBackgroundGreen"}
          left={-100}
          top={-100}
          zindex={-1}
        />
        <BackgroundPattern
          strokeColor={"pillBackgroundGreen"}
          fillColor={"pillBackgroundGreen"}
          right={-100}
          bottom={-100}
          zindex={-1}
        />

        <TitleStyle>{thankyouTitle}</TitleStyle>
        <BodyStyle>{thankyouMessage}</BodyStyle>
      </Container>
    );
  }

  return (
    <Container>
      <BackgroundPattern
        strokeColor={"pillBackgroundGreen"}
        fillColor={"pillBackgroundGreen"}
        left={-100}
        top={-100}
        zindex={-1}
      />
      <BackgroundPattern
        strokeColor={"pillBackgroundGreen"}
        fillColor={"pillBackgroundGreen"}
        right={-100}
        bottom={-100}
        zindex={-1}
      />
      <TitleStyle>{signupTitle}</TitleStyle>
      <BodyStyle>{signupMessage}</BodyStyle>
      <StayConnectedForm onClick={onClick} />
    </Container>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allEmail_popups {
            edges {
              node {
                signup_popup_message
                signup_popup_title
                thankyou_popup_message
                thankyou_popup_title
              }
            }
          }
        }
      }
    `}
    render={data => <EmailPopUp data={data} {...props} />}
  />
);
