import styled from "styled-components";

import { Col, Row } from "../../base_components/layout";
import { sizes } from "../../base_components/screenSizes";
import { SPsh1, SPh2, SPbody1 } from "../../base_components/typography";

import {
  white,
  grey,
  darkBlue,
  yellow,
  orange,
} from "../../base_components/colors";

export const ModalMain = styled.div`
  z-index: 20000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: ${props => (props.isModalVisible ? `initial` : `none`)};
`;

export const ModalBody = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: fixed;
  background: ${props => (props.isNotification ? `${darkBlue}` : `${white}`)};
  width: 60%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: ${props => (props.isNotification ? `0px` : "10px")};

  color: ${props => (props.isNotification ? `${white}` : `${grey}`)};

  @media (max-width: ${sizes.medium}px) {
    width: 90%;
  }

  @media (max-width: ${sizes.small}px) {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    display: flex;
    align-items: center;
    top: 0;
    transform: none;
    left: 0;
  }
`;

export const CircleImageHolder = styled.div`
  position: relative;
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid ${yellow};

  img {
    width: 100%;
    height: auto;
  }
`;

export const NameStyle = styled(SPh2)`
  color: ${grey};
  margin-top: 8px;
  @media (max-width: ${sizes.small}px) {
    text-align: center;
  }
`;

export const BioStyle = styled(SPbody1)`
  color: ${grey};
  margin-top: 8px;
`;

export const PositionStyle = styled(SPsh1)`
  color: ${grey};
  margin-top: 8px;
  text-align: left;
  @media (max-width: ${sizes.small}px) {
    text-align: center;
  }
`;

export const ModalContent = styled.div`
  padding: 50px;

  max-height: 50vh;
  overflow: ${props => (props.isNotification ? `visible` : `scroll`)};
  -ms-overflow-style: none;
  height: ${props => (props.isNotification ? `540px` : `auto`)};
  width: ${props => (props.isNotification ? `520px` : `100%`)};
  display: flex;
  align-items: ${props => (props.isNotification ? `center` : `initial`)};

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${sizes.medium}px) {
    padding: 20px;
  }

  @media (max-width: ${sizes.small}px) {
    max-height: 100%;
    min-height: 500px;
  }
`;

export const ModalCol = styled(Col)``;

export const ModalColImg = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const ModalRow = styled(Row)`
  align-items: center;
  flex-direction: row;

  @media (max-width: ${sizes.small}px) {
    flex-direction: ${props => (props.reverse ? `column-reverse` : `initial`)};
  }
`;

export const Bar1 = styled.div`
  width: 33px;
  height: 3px;
  background-color: ${props =>
    props.isNotification ? `${orange}` : `${darkBlue}`};

  margin: 8px 0;
  border-radius: 50px;
  -webkit-transform: rotate(-45deg) translate(-6px, 8.5px);
  transform: rotate(-45deg) translate(-6px, 8.5px);
`;
export const Bar2 = styled.div`
  width: 33px;
  height: 3px;
  background-color: ${props =>
    props.isNotification ? `${orange}` : `${darkBlue}`};

  margin: 8px 0;
  border-radius: 50px;
  -webkit-transform: rotate(45deg) translate(1px, -2px);
  transform: rotate(45deg) translate(1px, -2px);
`;

export const CloseContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  z-index: 1;
  padding-bottom: 0;
  position: fixed;
  right: 0;
  top: 0;

  @media (min-width: 0px) {
    padding-right: 30px;
    padding-top: 30px;
  }

  @media (max-width: ${sizes.medium}px) {
    padding: 25px;
  }
`;
